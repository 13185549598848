import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
//import reportWebVitals from './reportWebVitals';


window.addEventListener("DOMContentLoaded", function (e) {
  const elem = document.getElementById('root');
  const root = ReactDOM.createRoot(elem);
  root.render(
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

