import React, { useEffect, useState, useRef } from 'react';
import { FaBars, FaTimes, FaSignOutAlt } from 'react-icons/fa';
import { Oval } from 'react-loader-spinner'
import './App.css';
import io from 'socket.io-client';
import DynamicTable from './DynamicTable';
import BoMsTable from './BoMsTable';
import CostEstimateTable from './CostEstimateTable';
import FullEstimateTable from './FullEstimateTable';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useTranslation } from 'react-i18next';

import logo from './images/asumma-logo.png';

const App = () => {
  const { t, i18n } = useTranslation();

  const [signedIn, setSignedIn] = useState(false);
  const [isFlickering, setIsFlickering] = useState(false);
  const [data, setData] = useState([]);
  const [clientConnected, setClientConnected] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false); // New state for sidebar visibility
  const [sidebarHeight, setSidebarHeight] = useState(window.innerHeight + 'px');
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPicture, setUserPicture] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [canBeAdmin, setCanBeAdmin] = useState(false);
  const [jwtToken, setJwtToken] = useState(''); // Use state for JWT token
  const [userIdentifier, setUserIdentifier] = useState('');
  const [viewMode, setViewMode] = useState('calculation'); // Possible values: 'calculation', 'allprojects'
  const [loadingLatestBoMs, setLoadingLatestBoMs] = useState(false);
  const [bomdata, setBomdata] = useState([]);
  const [costEstimateData, setCostEstimateData] = useState([]);
  const [activeTab, setActiveTab] = useState('cost_estimate'); // New state for active tab
  const [language, setLanguage] = useState('en'); // New state for localized UI/user
  const [authError, setAuthError] = useState('');
  const [authLoading, setAuthLoading] = useState(false); // New state for authentication loading


  // Initialize socket connection
  const socketRef = useRef(null);

  // Authentication function
  const authenticate = (token, isAdminFlag) => {
    if (token && socketRef.current) {
    setAuthLoading(true); // Set loading to true when authentication starts
    socketRef.current.emit('authenticate', { id_token: token, client_type: 'web', request_admin: isAdminFlag });
    }
  };

  useEffect(() => {
    fetch('/config')
      .then(response => response.json())
      .then(config => {
        window.GOOGLE_CLIENT_ID = config.GOOGLE_CLIENT_ID;
        // Initialize Google Sign-In when the script is loaded
        if (window.google && window.google.accounts) {
          initializeGoogleSignIn();
        } else {
          window.addEventListener('google-loaded', initializeGoogleSignIn);
        }
  });

    checkUserSignInState();

    socketRef.current = io.connect(window.location.origin);

    const handleResize = () => {
      setSidebarHeight(`${window.innerHeight}px`);
    };

    // Listen for resize events
    window.addEventListener('resize', handleResize);

    // Set initial height
    handleResize();

    // Check if user info exists in localStorage
    const storedUserIdentifier = localStorage.getItem('userIdentifier');
    const storedGivenName = localStorage.getItem('givenName');
    const storedFamilyName = localStorage.getItem('familyName');
    const storedUserEmail = localStorage.getItem('userEmail');
    const storedUserPicture = localStorage.getItem('userPicture');
    const storedCanBeAdmin = localStorage.getItem('canBeAdmin');
    const storedData = JSON.parse(localStorage.getItem('data'));
    const storedCostEstimateData = JSON.parse(localStorage.getItem('costEstimateData'));
    const storedLanguage = localStorage.getItem('language');

    if (signedIn && storedUserIdentifier && storedGivenName && storedFamilyName && storedUserEmail && storedUserPicture) {
      setSignedIn(true);
      setUserIdentifier(storedUserIdentifier);
      setGivenName(storedGivenName);
      setFamilyName(storedFamilyName);
      setUserEmail(storedUserEmail);
      setUserPicture(storedUserPicture);
      setCanBeAdmin(storedCanBeAdmin);
    }
    if (storedData) {
      setData(storedData);
    }
    if (storedCostEstimateData) {
      setCostEstimateData(storedCostEstimateData);
    }
    if (storedLanguage) {
      setLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }

    socketRef.current.on('connect', (data) => {
      console.log('Socket connected!', storedCanBeAdmin)

//      const localJwtToken = localStorage.getItem('jwt_token');
      authenticate(localStorage.getItem('jwt_token'), isAdmin); // Re-authenticate using the stored JWT token
//      if (localJwtToken) {
//          socket.emit('authenticate', { id_token: localJwtToken, client_type: 'web' });
//      }

//      is_connected = true;
    });
    socketRef.current.on('authenticated', (data) => {
      console.log("authenticated: ", data);
      // Assuming the server sends user info like this:
      if (data.status === 'authenticated') {
        setSignedIn(true);
        setAuthError('');
        setAuthLoading(false);
        setUserIdentifier(data.user_id);
        setGivenName(data.user_info.given_name);
        setFamilyName(data.user_info.family_name);
        setUserEmail(data.user_info.email);
        setUserPicture(data.user_info.picture);
        setCanBeAdmin(data.user_info.is_admin);

        const lng = data.language ? data.language : 'en';
        setLanguage(lng)
        i18n.changeLanguage(lng);


        // Store user info in localStorage
        localStorage.setItem('userIdentifier', data.user_id);
        localStorage.setItem('givenName', data.user_info.given_name);
        localStorage.setItem('familyName', data.user_info.family_name);
        localStorage.setItem('userEmail', data.user_info.email);
        localStorage.setItem('userPicture', data.user_info.picture);
        localStorage.setItem('canBeAdmin', data.user_info.is_admin);
        // RSet the initial request type
        handleRequest(data.user_id, 'cost_estimate');
      } else {

        setAuthError(data.error);
        setAuthLoading(false); // Set loading to false after authentication check
        setSignedIn(false);
        signOut();
      }

    });
    socketRef.current.on('client_connected', (data) => {
      console.log('Client connected: ', data);
      setClientConnected(true);
    });

    socketRef.current.on('client_disconnected', () => {
      console.log('Client disconnected!')
      setClientConnected(false);
    });
    socketRef.current.on('bom_update_start', () => {
      console.log('bom_update_start:', data); // Handle the data as needed
      setIsFlickering(true);
    });
    socketRef.current.on('bom_update_done', data => {
      setIsFlickering(false);
      setData(data); // Update the state with the received data
      localStorage.setItem('data', JSON.stringify(data));
      console.log('BoM received:', data); // Handle the data as needed
    });
    socketRef.current.on('cost_estimate_start', data => {
      console.log('cost_estimate_start:', data); // Handle the data as needed
      setIsFlickering(true);
    });
    socketRef.current.on('cost_estimate_done', data => {
      setIsFlickering(false);
      setCostEstimateData(data); // Update the state with the received data
      localStorage.setItem('costEstimateData', JSON.stringify(data));
      console.log('Cost estimate received:', data); // Handle the data as needed
    });
    socketRef.current.on('connect_error', (error) => {
      console.error('Connection error:', error);
      setLoadingLatestBoMs(false); // Also stop loading on error
    });
    socketRef.current.on('latest-boms-response', (latestBoMsData) => {
      console.log('Latest BoMs received:', latestBoMsData);

      if (latestBoMsData && latestBoMsData.status === "Unauthorized") {
        return
      }
      // Update the state or handle the data as needed
      setBomdata(latestBoMsData); // For example, reusing the existing 'data' state
      setLoadingLatestBoMs(false); // Stop loading once data is received
    });
    // Clean up on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      socketRef.current.off('authenticated');
      socketRef.current.off('connect_error');
      socketRef.current.off('client_connected');
      socketRef.current.off('client_disconnected');
      socketRef.current.off('latest-boms-response');

      socketRef.current.off('connect');
      socketRef.current.off('bom_update_start');
      socketRef.current.off('bom_update_done');
      socketRef.current.off('cost_estimate_start');
      socketRef.current.off('cost_estimate_done');
      socketRef.current.disconnect();
    };

  }, []);

  const initializeGoogleSignIn = () => {
    if (window.google && window.google.accounts) {
      window.google.accounts.id.initialize({
        client_id: window.GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("g_id_signin"),
        { theme: "outline", size: "large" }
      );
    }
  };

  useEffect(() => {
    // Ensure the Google Sign-In button is initialized after the component mounts and auth state changes
    initializeGoogleSignIn();
  }, [authError, authLoading]);


  // Google Sign-In
  const handleCredentialResponse = (response) => {
    console.log('Successfully obtained OAUTH token: currentUser:', response);

    const token = response.credential;
    setJwtToken(token); // Update JWT token state
    localStorage.setItem('jwt_token', token); // Store JWT token
//    setSignedIn(true);

    authenticate(token, isAdmin); // Authenticate with the latest token and isAdmin state
  };

  const fetchLatestBoMs = () => {
    // Toggle between 'calculation' and 'allprojects' viewModes
    const newViewMode = viewMode === 'calculation' ? 'allprojects' : 'calculation';
    setViewMode(newViewMode);
    setLoadingLatestBoMs(true); // Start loading

    // If switching to 'allprojects', fetch the latest BoMs
    if (newViewMode === 'allprojects' && socketRef.current) {
      socketRef.current.emit('fetch-all-latest-project-boms', { user_id: userIdentifier });
    }
  };

  const handleRequest = (userId, requestType) => {
    setActiveTab(requestType);
    if (socketRef.current) {
      console.log("request_calculation: " + requestType)
      socketRef.current.emit('request_calculation', { user_id: userId, request_type: requestType });
    }
  };

  // Admin Mode Checkbox Handler
  const requestCompleteBom = () => {
    console.log("Requesting full BoM: ", isAdmin)
    const localJwtToken = localStorage.getItem('jwt_token');
    authenticate(localJwtToken, isAdmin); // Re-authenticate with updated admin status
  };

  const signOut = () => {
    localStorage.removeItem('userSignedIn');
    localStorage.removeItem('jwt_token'); // Clear JWT token
    localStorage.removeItem('givenName');
    localStorage.removeItem('familyName');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userPicture');
    localStorage.removeItem('data');
    localStorage.removeItem('costEstimateData');
    localStorage.removeItem('language');
    setSignedIn(false);
    setGivenName('');
    setFamilyName('');
    setAuthLoading(false);
    setUserEmail('');
    setUserPicture('');
    setData([]);
    setCostEstimateData([]);
    setLanguage('');
    window.location.reload();
  };

  const checkUserSignInState = () => {
    const isSignedIn = localStorage.getItem('userSignedIn') === 'true';
    setSignedIn(isSignedIn);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const hasData = (data.Walls?.details?.length > 0) || (data.Roofs?.details?.length > 0) || (data.Beams?.details?.length > 0);
  const hasCostEstimateData = costEstimateData && costEstimateData['cost_estimate'] && Array.isArray(costEstimateData['cost_estimate']['summary']);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng)
    const localJwtToken = localStorage.getItem('jwt_token');
 
    socketRef.current.emit('update_user_preferences', { id_token: localJwtToken, user_id: userIdentifier, user_preferences: {language: lng} });
  };

  return (
    <div className={`container ${sidebarOpen ? 'sidebar-open' : ''}`}>
      <button className="hamburger" onClick={toggleSidebar}>
       {sidebarOpen ? <FaTimes /> : <FaBars />}
      </button> {/* Hamburger Icon */}
      <aside className={`sidebar ${sidebarOpen ? 'open' : ''}`} style={{ height: sidebarHeight }}>
        <div className="sidebar-content">
          {signedIn && (
            <>
              <div className="sidebar-header">
                  <img src={logo} alt="asumma Logo" className="sidebar-logo" />
              </div>
              <div className="profile-info">
                <div className="profile-info-content">
                  <div className="connection-status">
                    <p id="waiting_for_client">
                      <span className={`circle ${clientConnected ? 'connected' : 'disconnected'}`}></span> 
                      {clientConnected ? t('archicad_connected') : t('archicad_disconnected')}
                    </p>
                  </div>
                  {isAdmin === true && (
                    <button className="fetch-boms-button" onClick={fetchLatestBoMs}>
                      {viewMode === 'calculation' ? t('fetch_latest_boms') : t('show_calculation')}
                    </button>
                  )}
                  <div className="user-info">
                    { userPicture !== "" && (
                      <div>
                        <img src={userPicture} alt="Profile" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
                      </div>
                      )}
                    <div>
                      <p>{givenName} {familyName}</p>
                      <p>{userEmail}</p>
                    </div>
                  </div>
                  { canBeAdmin === true && (
                  <div>
                    { console.log("canBeAdmin console", canBeAdmin)}
                    <label>
                      <input className="input-sidebar"
                        type="checkbox"
                        checked={isAdmin}
                        onChange={e => {
                          const newIsAdminValue = e.target.checked;
                          setIsAdmin(newIsAdminValue); // Update state with the new value
                          console.log("Toggling Admin Mode to: ", newIsAdminValue);
                          // Use the new value directly for re-authentication
                          const localJwtToken = localStorage.getItem('jwt_token');
                          authenticate(localJwtToken, newIsAdminValue);
                        }}
                      />
                      {t('admin_mode')}
                    </label>
                  </div>
                  )}
                 <div className="language-selector">
                    <a href="" onClick={(e) => { e.preventDefault(); changeLanguage('en') }}>EN</a> | <a href="" onClick={(e) => { e.preventDefault(); changeLanguage('fi') }}>FI</a>
                  </div>
                  <button className="sign-out-button" onClick={signOut}><FaSignOutAlt />{t('sign_out')}</button>
                </div>
              </div>
            </>
          )}
        </div>
      </aside>
      {!signedIn && (
        <div className="login-box">
          <div className="login-content">
            <div>
              <img src={logo} alt="asumma Logo" className="sidebar-logo" />
            </div>
            <h4>{t('welcome')}</h4>
            {authLoading ? (
              <div className='login-loading'>
                <Oval
                  visible={true}
                  height="80"
                  width="80"
                  color="#363D44"
                  ariaLabel="oval-loading"
                  secondaryColor="#C3C2BB"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            ) : (<>
              <div id="g_id_signin" className="sign-in-button"></div>
              {authError && <p className="error-message">{authError}</p>}
              </>
            )}
          </div>
        </div>
      )}
      <main className="main-content">

        {signedIn && (
          <Tabs selectedIndex={activeTab === 'cost_estimate' ? 0 : activeTab === 'full_estimate' ? 1 : 2} onSelect={index => handleRequest(userIdentifier, index === 0 ? 'cost_estimate' : index === 1 ? 'full_estimate' : 'bom')}>
            <TabList>
              <Tab>{t('cost_estimate_summary')}</Tab>
              <Tab>{t('cost_estimate_details')}</Tab>
              <Tab>{t('bill_of_materials')}</Tab>
            </TabList>

            <TabPanel>             
                <div>
                  <p id="calculation-state"><span className="circle-wrapper"><span className={`circle ${isFlickering ? 'animate-opacity animate-scale' : ''}`}></span></span>{`${isFlickering ? t('updating') : t('up_to_date')}`}</p>
                  {viewMode === 'calculation' && hasCostEstimateData && <CostEstimateTable data={costEstimateData['cost_estimate']['summary']} />}
                </div>
            </TabPanel>
            <TabPanel>             
                <div>
                  <p id="calculation-state"><span className="circle-wrapper"><span className={`circle ${isFlickering ? 'animate-opacity animate-scale' : ''}`}></span></span>{`${isFlickering ? t('updating') : t('up_to_date')}`}</p>
                  {viewMode === 'calculation' && hasCostEstimateData && <FullEstimateTable data={costEstimateData['cost_estimate']['complete']} />}
                </div>
            </TabPanel>
            <TabPanel>
                <div>
                  <p id="calculation-state"><span className="circle-wrapper"><span className={`circle ${isFlickering ? 'animate-opacity animate-scale' : ''}`}></span></span>{`${isFlickering ? t('updating') : t('up_to_date')}`}</p>
                  {viewMode === 'calculation' && hasData && <DynamicTable data={data} />}
                  {viewMode === 'allprojects' && !loadingLatestBoMs && bomdata.map((bom, index) => (
                    <BoMsTable key={index} project={bom} />
                  ))}
                </div>
            </TabPanel>
          </Tabs>
        )}
      </main>
    </div>
  );
};

export default App;
