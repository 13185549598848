import React from 'react';
import './App.css'; // Make sure to import your CSS file
// import CostPieChart from './CostPieChart';

const formatPercentage = (value) => {
  return typeof value === 'number' ? `${Math.round(value * 100)}%` : value;
};

const formatNumber = (value) => {
  if (typeof value !== 'number') return value;

  return Math.round((value + Number.EPSILON) * 100) / 100
}

const formatEuro = (value) => {
  if (typeof value !== 'number') return value;

  let num = Math.round(value);
  num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return `${num} €`;
};

const formatArea = (value) => {
  return typeof value === 'number' ? `${value} m²` : value;
};

const formatEurPerArea = (value) => {
  return formatEuro(value) + ' /m²';
};

const containsNumberFollowedByNull = (row) => {
  return row.some((item, index) => typeof item === 'string' && /\d+/.test(item) && row[index + 1] === null);
};

const isRowEmpty = (row) => {
  return row.every(item => item === null || item === undefined || item === '');
};

const FullEstimateTable = ({ data }) => {
  if (!data || data.length === 0) return null;

  // Filter out empty rows
  const filteredData = data.filter(row => !isRowEmpty(row));

  const headerColumns = data[0].map((_, index) => (
    <th key={index}>Column {index + 1}</th>
  ));

  return (
    <div className="cost-estimate-table printable-area">
      <table className="cost-estimate-table-styled-table full-estimate">
        <thead>
          <tr>
          {headerColumns}
          </tr>
        </thead>
        <tbody>
          {filteredData.map((row, rowIndex) => {
            const isHiddenRow = containsNumberFollowedByNull(row);
            const isBoldRow = false; // = rowIndex === 13; // Row 14 (0-based index 13)
            return (
              <tr key={rowIndex} className={isHiddenRow ? 'hidden-row' : isBoldRow ? 'bold-row' : ''}>
                {row.map((cell, cellIndex) => {
                  const isPercentageCell = false; //row.length - cellIndex <= 3;
                  const isEuroCell = rowIndex >= 1 && cellIndex >= 9;
                  const isAreaCell = false; //rowIndex >= 20 && rowIndex < 23 && cellIndex >= 4 && cellIndex <= 6;
                  const isEurPerAreaCell = false; //rowIndex >= 23 && rowIndex < 26 && cellIndex >= 4 && cellIndex <= 6;
                  const isLastTwoRows = false; //rowIndex >= data.length - 2;
                  const isFourthColumn = false; //cellIndex === 3;

                  // Check the length of the first column's string value
                  const firstColumnValue = row[0];
                  let padLeftClass = '';
                  if (typeof firstColumnValue === 'string') {
                    if (firstColumnValue.length === 2) {
                      padLeftClass = 'pad-left-2';
                    } else if (firstColumnValue.length === 3) {
                      padLeftClass = 'pad-left-3';
                    } else if (firstColumnValue.length === 4) {
                      padLeftClass = 'pad-left-4';
                    }
                  }

                  return (
                    <td key={cellIndex}  className={padLeftClass}>
                      {isPercentageCell ? formatPercentage(cell) : isEuroCell ? formatEuro(cell) : isAreaCell ? formatArea(cell) : isEurPerAreaCell ? formatEurPerArea(cell) : formatNumber(cell)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FullEstimateTable;
