import React from 'react';

const formatTitle = (title) => {
	return title
		.replace(/_/g, ' ')
		.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
};

const formatDimensions = (dimensions) => {
	return `Length ${dimensions.length}, Height ${dimensions.height}`;
};

const renderCellValue = (value) => {
	if (typeof value === 'object' && value !== null) {
		return JSON.stringify(value, null, 2);
	}
	return value;
};

const MaterialsTable = ({ materials, dimensions, totalCost, elementName }) => {
//    if (materials.length === 0 && totalCost === 0) return null;
    if (materials === undefined && totalCost === undefined) return null;

    const columns = (materials && materials.length) ? Object.keys(materials[0]) : ['Total Cost']; // Ensure there's at least one column
    const colspanForDimensions = (materials && materials.length) ? columns.length : 2; // Set a minimum of 2 for the colspan if no materials

    return (
        <div className="material-table">
            <table className="styled-table">
                <thead>
                    <tr className="dimensions-row">
                        {/* Ensure the dimensions row spans the full table width */}
                        <th colSpan={colspanForDimensions}>{elementName}: {formatDimensions(dimensions)}</th>
                    </tr>
                    {materials && materials.length > 0 && (
                        <tr>
                            {columns.map((col) => (
                                <th key={col}>{formatTitle(col)}</th>
                            ))}
                        </tr>
                    )}
                </thead>
                <tbody>
                    { materials && materials.map((material, index) => (
                        <tr key={index}>
                            {columns.map((col) => (
                                <td key={`${index}-${col}`}>{renderCellValue(material[col])}</td>
                            ))}
                        </tr>
                    ))}
                    {/* Adjust colspan for total cost to ensure it spans the correct width */}
                    <tr className="total-cost-row">
                        <td colSpan={(materials && materials.length) ? columns.length - 1 : 1}>Total Cost</td>
                        <td>{totalCost}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

const DynamicTables = ({ data }) => {
	return (
		<div className="content-table">
			{Object.entries(data).map(([type, elements]) => (
				<React.Fragment key={type}>
					<h2>{type}: {elements.total_cost} €</h2>
					{elements.details.map((element, index) => (
						<MaterialsTable
							key={type + index}
							elementName={element.element_name}
							materials={element.materials}
							dimensions={element.dimensions}
							totalCost={element.total_cost}
						/>
					))}
				</React.Fragment>
			))}
		</div>
	);
};

export default DynamicTables;
