import React from 'react';

const formatTitle = (title) => {
	return title
		.replace(/_/g, ' ')
		.replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
};

const formatDimensions = (dimensions) => {
	return `Length ${dimensions.length}, Height ${dimensions.height}`;
};

const renderCellValue = (value) => {
	if (typeof value === 'object' && value !== null) {
		return JSON.stringify(value, null, 2);
	}
	return value;
};

const BoMsTable = ({ project }) => {
  // Parse the JSON strings to objects. Add checks for undefined latest_bom if necessary.
  const fullBom = project ? JSON.parse(project.latest_bom.full_bom_json) : {};

  // Dynamically render BoM details based on available categories
  const renderBoMDetails = () => {
    // Iterate over each category in the fullBom object
    return Object.entries(fullBom).map(([category, items], index) => {
      if (items.length === 0) return null; // Skip categories with no items

      // Render items for each category
      return (
        <React.Fragment key={index}>
          <tr>
            <th colSpan="4">{category}</th> {/* Category header */}
          </tr>
          {items.map((item, itemIndex) => (
            <tr key={itemIndex}>
              <td>{item.element_name}</td>
              <td>{item.dimensions ? `${item.dimensions.length} x ${item.dimensions.height} meters` : 'N/A'}</td>
              <td>{item.total_cost}</td>
              <td>
                {item.materials?.map((material, matIndex) => (
                  <div key={matIndex}>
                    {`${material.material_name} - ${material.quantity_needed} ${material.unit} ($${material.lowest_price} per unit from ${material.supplier})`}
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </React.Fragment>
      );
    });
  };

  return (
    <div className="content-table">
      <h2>Bill of Materials: {project ? project.latest_bom.bom_name : 'N/A'}</h2>
      <table>
        <thead>
          <tr>
            <th>Element Name</th>
            <th>Dimensions</th>
            <th>Total Cost</th>
            <th>Materials</th>
          </tr>
        </thead>
        <tbody>
          {renderBoMDetails()}
        </tbody>
      </table>
    </div>
  );
};

export default BoMsTable;
