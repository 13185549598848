import React from 'react';
import './App.css'; // Make sure to import your CSS file
// import CostPieChart from './CostPieChart';

const determineColspan = (row) => {
  const nonEmptyItems = row.filter(item => item !== '');
  if (nonEmptyItems.length < 2) {
    return null; // Not enough non-empty items to determine colspan
  }

  const firstNonEmptyIndex = row.indexOf(nonEmptyItems[0]);
  const secondNonEmptyIndex = row.indexOf(nonEmptyItems[1]);

  const emptyCellsBetween = secondNonEmptyIndex - firstNonEmptyIndex - 1;

  if (firstNonEmptyIndex === 0 && row[1] === '') {
    return 4; // First cell non-empty and the next one is empty
  } else if (emptyCellsBetween === 0) {
    return 3; // No empty cell between the first two non-empty items
  } else if (emptyCellsBetween === 1) {
    return 2; // One empty cell between the first two non-empty items
  } else if (emptyCellsBetween === 2) {
    return 2; // Two empty cells between the first two non-empty items
  }

  return 1; // Default colspan
};

const formatPercentage = (value) => {
  return typeof value === 'number' ? `${Math.round(value * 100)}%` : value;
};

const formatEuro = (value) => {
  if (typeof value !== 'number') return value;

  const parts = value.toFixed(2).split('.');
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `€ ${integerPart}`;
};

const formatArea = (value) => {
  return typeof value === 'number' ? `${value} m²` : value;
};

const formatEurPerArea = (value) => {
  return formatEuro(value) + ' /m²';
};

const containsNumberFollowedByNull = (row) => {
  return row.some((item, index) => typeof item === 'string' && /\d+/.test(item) && row[index + 1] === null);
};


const isRowEmpty = (row) => {
  return row.every(item => item === null || item === undefined || item === '');
};

const CostEstimateTable = ({ data }) => {
  if (!data || data.length === 0) return null;

  // Filter out empty rows
  const filteredData = data.filter(row => (!isRowEmpty(row) && !containsNumberFollowedByNull(row)));

  const headerColumns = data[0].map((_, index) => (
    <th key={index}>Column {index + 1}</th>
  ));

  return (
    <div className="cost-estimate-table printable-area">
      <table className="cost-estimate-table-styled-table">
        <thead>
          <tr>
          {headerColumns}
          </tr>
        </thead>
        <tbody>
          {filteredData.map((row, rowIndex) => {
            const isHiddenRow = containsNumberFollowedByNull(row);
            const colspan = determineColspan(row);
            const isBoldRow = rowIndex === 11; // Row 14 (0-based index 13)
            return (
              <tr key={rowIndex} className={isHiddenRow ? 'hidden-row' : isBoldRow ? 'bold-row' : ''}>
                {row.map((cell, cellIndex) => {
                  const isPercentageCell = row.length - cellIndex <= 3;
                  const isEuroCell = rowIndex >= 12 && (rowIndex < 17 || rowIndex >= 21) && cellIndex >= 4 && cellIndex <= 6;
                  const isAreaCell = rowIndex >= 17 && rowIndex < 19 && cellIndex >= 4 && cellIndex <= 6;
                  const isEurPerAreaCell = rowIndex >= 19 && rowIndex < 21 && cellIndex >= 4 && cellIndex <= 6;
                  const isLastTwoRows = rowIndex >= data.length - 2;
                  const isFourthColumn = cellIndex === 3;

                  if (isLastTwoRows && cellIndex === 0) {
                    return (
                      <td key={cellIndex} colSpan={4} className="summary-text">
                        {formatEuro(row[3])}
                      </td>
                    );
                  }

                  // Determine if the cell should have a colspan
                  if (colspan && cellIndex === row.indexOf(row.filter(item => item !== '')[1])) {
                    return (
                      <td key={cellIndex} colSpan={colspan}>
                        {isPercentageCell ? formatPercentage(cell) : isEuroCell ? formatEuro(cell) : isAreaCell ? formatArea(cell) : isEurPerAreaCell ? formatEurPerArea(cell) : cell}
                      </td>
                    );
                  }

                  // Skip cells that are included in the colspan
                  if (colspan && cellIndex > row.indexOf(row.filter(item => item !== '')[1]) && cellIndex < row.indexOf(row.filter(item => item !== '')[1]) + colspan) {
                    return null;
                  }

                  // Skip cells that are included in the last two rows' colspan
                  if (isLastTwoRows && cellIndex < 4) {
                    return null;
                  }

                  return (
                    <td key={cellIndex}>
                      {isPercentageCell ? formatPercentage(cell) : (isEuroCell || (rowIndex >= 15 && cellIndex >= 4 && cellIndex <= 6) ? formatEuro(cell) : cell)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CostEstimateTable;
